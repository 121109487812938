function Title() {
  return (
    <div className="absolute top-32 md:top-[16rem] lg:top-[17rem] px-3 sm:px-7 md:px-10 space-y-2">
    <h2 className="text-white text-lg font-bold md:text-3xl lg:text-4xl ">
      Mission is about people
    </h2>
    <p className="text-white text-md md:text-xl md:font-semibold lg:text-2xl">
      Develop sustainanble technology
      <br />
      for aquaculture farming by solving critical water and disease issues
    </p>
  </div>
  );
  
}

export default Title;
 